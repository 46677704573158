import React from "react";
import Logo from '../../assetes/image/logo.png'

export default function Sidebar(){
    return(
        <aside className="fixed top-0	 left-0 p-10 w-72 h-screen min-h-96 overflow-auto at-sidebarwrapper">
            <div className="w-full flaot-left">
                <strong className="float-left w-full pb-16">
                    <a href="/">
                        <img src={Logo} alt="Logo"/>
                    </a>
                </strong>
                <div className="w-full float-left pb-16">
                    <div className="w-full float-left pb-7">
                        <h3 className="text-white	text-sm font-medium">MAIN MENU</h3>
                    </div>
                    <div className="w-full float-left">
                        <nav className="w-full float-left">
                            <ul className="flex flex-col gap-8 nav-list">
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g clip-path="url(#clip0_15_656)">
                                          <path d="M24.325 10.8738C24.3244 10.8732 24.3238 10.8727 24.3233 10.8721L14.1253 0.674438C13.6906 0.239563 13.1126 0 12.4979 0C11.8832 0 11.3052 0.239372 10.8704 0.674248L0.677684 10.8667C0.67425 10.8702 0.670817 10.8738 0.667384 10.8772C-0.225255 11.775 -0.223729 13.2317 0.671771 14.1272C1.0809 14.5365 1.62125 14.7736 2.19898 14.7984C2.22245 14.8006 2.2461 14.8018 2.26994 14.8018H2.67639V22.3066C2.67639 23.7917 3.8847 25 5.37014 25H9.35993C9.76429 25 10.0924 24.6721 10.0924 24.2676V18.3838C10.0924 17.7061 10.6436 17.1549 11.3213 17.1549H13.6745C14.3522 17.1549 14.9035 17.7061 14.9035 18.3838V24.2676C14.9035 24.6721 15.2313 25 15.6359 25H19.6257C21.1111 25 22.3194 23.7917 22.3194 22.3066V14.8018H22.6963C23.3109 14.8018 23.8888 14.5624 24.3238 14.1275C25.2203 13.2305 25.2207 11.7714 24.325 10.8738Z" fill="#B1B1B1"/>
                                          </g>
                                          <defs>
                                          <clipPath id="clip0_15_656">
                                          <rect width="25" height="25" fill="white"/>
                                          </clipPath>
                                          </defs>
                                      </svg>
                                      </em>
                                      <span>
                                      Home
                                      </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                          <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M23.4 9.21053V7.89474L15.6 0H2.6C1.157 0 0 1.17105 0 2.63158V21.0526C0 21.7506 0.273928 22.4199 0.761522 22.9134C1.24912 23.407 1.91044 23.6842 2.6 23.6842H10.4V21.2237L21.307 10.1842C21.879 9.60526 22.607 9.28947 23.4 9.21053ZM14.3 1.97368L21.45 9.21053H14.3V1.97368ZM25.805 14.7237L24.531 16.0132L21.879 13.3289L23.153 12.0395C23.4 11.7763 23.829 11.7763 24.089 12.0395L25.805 13.7763C26.065 14.0395 26.065 14.4737 25.805 14.7237ZM20.969 14.25L23.621 16.9342L15.652 25H13V22.3158L20.969 14.25Z" fill="#B1B1B1"/>
                                          </svg>
                                      </em>
                                      <span>
                                      Post in Community
                                      </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.44446 0C1.06136 0 0.693961 0.152184 0.423072 0.423072C0.152184 0.693961 0 1.06136 0 1.44446V13.0001C0 13.3832 0.152184 13.7506 0.423072 14.0215C0.693961 14.2924 1.06136 14.4446 1.44446 14.4446H2.88892V17.3335C2.88898 17.6191 2.97373 17.8984 3.13245 18.1358C3.29117 18.3733 3.51674 18.5584 3.78065 18.6677C4.04455 18.777 4.33493 18.8056 4.61509 18.7499C4.89525 18.6942 5.1526 18.5567 5.35461 18.3547L9.26476 14.4446H17.3335C17.7166 14.4446 18.084 14.2924 18.3549 14.0215C18.6258 13.7506 18.778 13.3832 18.778 13.0001V1.44446C18.778 1.06136 18.6258 0.693961 18.3549 0.423072C18.084 0.152184 17.7166 0 17.3335 0H1.44446Z" fill="#B1B1B1"/>
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25488 20.533C7.30303 20.4887 7.35022 20.4434 7.39644 20.3972L10.4616 17.3335H17.3329C18.4822 17.3335 19.5844 16.8769 20.397 16.0643C21.2097 15.2516 21.6662 14.1494 21.6662 13.0001V7.22229H24.5552C24.9383 7.22229 25.3057 7.37447 25.5766 7.64536C25.8474 7.91625 25.9996 8.28365 25.9996 8.66675V20.2224C25.9996 20.6055 25.8474 20.9729 25.5766 21.2438C25.3057 21.5147 24.9383 21.6669 24.5552 21.6669H23.1107V24.5558C23.1106 24.8414 23.0259 25.1206 22.8672 25.3581C22.7084 25.5956 22.4829 25.7807 22.219 25.89C21.9551 25.9993 21.6647 26.0279 21.3845 25.9722C21.1044 25.9165 20.847 25.779 20.645 25.577L16.7349 21.6669H8.66612C8.33688 21.667 8.01748 21.5546 7.76083 21.3484C7.50417 21.1422 7.32567 20.8545 7.25488 20.533Z" fill="#B1B1B1"/>
                                          </svg>
                                      </em>
                                      <span>
                                      Messages
                                      </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/" className="flex items-center space-x-3	at-active">
                                      <em>
                                          <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                                              <g clip-path="url(#clip0_15_660)">
                                              <path d="M1 0V6.25H7V0H1ZM10.375 0V6.25H25V0H10.375ZM1 9.375V15.625H7V9.375H1ZM10.375 9.375V15.625H25V9.375H10.375ZM1 18.75V25H7V18.75H1ZM10.375 18.75V25H25V18.75H10.375Z" fill="#B1B1B1"/>
                                              </g>
                                              <defs>
                                              <clipPath id="clip0_15_660">
                                              <rect width="24" height="25" fill="white" transform="translate(1)"/>
                                              </clipPath>
                                              </defs>
                                          </svg>
                                      </em>
                                      <span>
                                      Explore Listings
                                      </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                          <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M8.70087 11.7647C10.2393 11.7647 11.7147 11.145 12.8025 10.0418C13.8903 8.93865 14.5015 7.44245 14.5015 5.88235C14.5015 4.32226 13.8903 2.82606 12.8025 1.7229C11.7147 0.619746 10.2393 0 8.70087 0C7.16246 0 5.68706 0.619746 4.59924 1.7229C3.51142 2.82606 2.90029 4.32226 2.90029 5.88235C2.90029 7.44245 3.51142 8.93865 4.59924 10.0418C5.68706 11.145 7.16246 11.7647 8.70087 11.7647ZM21.7522 11.7647C22.906 11.7647 24.0125 11.2999 24.8284 10.4725C25.6443 9.64516 26.1026 8.52301 26.1026 7.35294C26.1026 6.18287 25.6443 5.06072 24.8284 4.23335C24.0125 3.40599 22.906 2.94118 21.7522 2.94118C20.5984 2.94118 19.4918 3.40599 18.676 4.23335C17.8601 5.06072 17.4017 6.18287 17.4017 7.35294C17.4017 8.52301 17.8601 9.64516 18.676 10.4725C19.4918 11.2999 20.5984 11.7647 21.7522 11.7647ZM3.62536 14.7059C1.62416 14.7059 0 16.3529 0 18.3824C0 18.3824 0 25 8.70087 25C15.5978 25 17.0276 20.8412 17.3234 19.1176C17.4017 18.6676 17.4017 18.3824 17.4017 18.3824C17.4017 16.3529 15.7776 14.7059 13.7764 14.7059H3.62536ZM20.2904 19.4118C20.2846 19.5294 20.273 19.6686 20.2556 19.8294C20.0956 21.0421 19.6801 22.2057 19.0375 23.2412C19.8061 23.4206 20.7023 23.5294 21.7493 23.5294C29 23.5294 29 18.3824 29 18.3824C29 16.3529 27.3758 14.7059 25.3746 14.7059H19.1999C19.896 15.7588 20.2991 17.0206 20.2991 18.3824V19.1176L20.2904 19.4118Z" fill="#B1B1B1"/>
                                          </svg>
                                      </em>
                                      <span>
                                      Holders
                                      </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                          <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M17.1111 2.54545H20.7778C21.1019 2.54545 21.4128 2.67954 21.642 2.91823C21.8712 3.15691 22 3.48063 22 3.81818V26.7273C22 27.0648 21.8712 27.3885 21.642 27.6272C21.4128 27.8659 21.1019 28 20.7778 28H1.22222C0.898069 28 0.587192 27.8659 0.357981 27.6272C0.128769 27.3885 0 27.0648 0 26.7273V3.81818C0 3.48063 0.128769 3.15691 0.357981 2.91823C0.587192 2.67954 0.898069 2.54545 1.22222 2.54545H4.88889V0H7.33333V2.54545H14.6667V0H17.1111V2.54545ZM4.88889 10.1818V12.7273H17.1111V10.1818H4.88889ZM4.88889 15.2727V17.8182H17.1111V15.2727H4.88889Z" fill="#B1B1B1"/>
                                          </svg>
                                      </em>
                                      <span>
                                      Schedules
                                      </span>
                                  </a>
                                </li>
                                <li>
                                    <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                        <em>
                                            <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.2 4.3125V14.375C4.2 15.9607 5.45563 17.25 7 17.25H25.2C26.7444 17.25 28 15.9607 28 14.375V4.3125C28 2.72676 26.7444 1.4375 25.2 1.4375H7C5.45563 1.4375 4.2 2.72676 4.2 4.3125ZM7 11.5C8.54437 11.5 9.8 12.7893 9.8 14.375H7V11.5ZM9.8 4.3125C9.8 5.89824 8.54437 7.1875 7 7.1875V4.3125H9.8ZM25.2 11.5V14.375H22.4C22.4 12.7893 23.6556 11.5 25.2 11.5ZM22.4 4.3125H25.2V7.1875C23.6556 7.1875 22.4 5.89824 22.4 4.3125ZM12.6 9.34375C12.6 8.39063 12.9687 7.47654 13.6251 6.80259C14.2815 6.12863 15.1717 5.75 16.1 5.75C17.0283 5.75 17.9185 6.12863 18.5749 6.80259C19.2313 7.47654 19.6 8.39063 19.6 9.34375C19.6 10.2969 19.2313 11.211 18.5749 11.8849C17.9185 12.5589 17.0283 12.9375 16.1 12.9375C15.1717 12.9375 14.2815 12.5589 13.6251 11.8849C12.9687 11.211 12.6 10.2969 12.6 9.34375ZM2.1 5.39062C2.1 4.79316 1.63188 4.3125 1.05 4.3125C0.468125 4.3125 0 4.79316 0 5.39062V16.1719C0 19.1502 2.34937 21.5625 5.25 21.5625H22.75C23.3319 21.5625 23.8 21.0818 23.8 20.4844C23.8 19.8869 23.3319 19.4062 22.75 19.4062H5.25C3.50875 19.4062 2.1 17.9598 2.1 16.1719V5.39062Z" fill="#B1B1B1"/>
                                            </svg>
                                        </em>
                                        <span>
                                        Bills & Payments
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="w-full float-left pb-16">
                    <div className="w-full float-left pb-7">
                        <h3 className="text-white	text-sm font-medium">SETTINGS</h3>
                    </div>
                    <div className="w-full float-left">
                        <nav className="w-full float-left">
                            <ul className="flex flex-col gap-8	nav-list">
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M21.2561 12.5C21.2561 12.2125 21.2438 11.9375 21.2191 11.65L23.5146 9.8875C24.0083 9.5125 24.144 8.8125 23.8355 8.2625L21.5276 4.225C21.3797 3.96023 21.1403 3.76018 20.8556 3.66327C20.5708 3.56637 20.2607 3.57945 19.9849 3.7L17.3315 4.8375C16.8749 4.5125 16.3936 4.225 15.8876 3.9875L15.5297 1.1C15.4556 0.475 14.9249 0 14.3079 0H9.70448C9.07507 0 8.54438 0.475 8.47033 1.1L8.11243 3.9875C7.60643 4.225 7.12511 4.5125 6.66848 4.8375L4.01505 3.7C3.44735 3.45 2.78091 3.675 2.47237 4.225L0.16451 8.275C-0.144028 8.825 -0.00827135 9.5125 0.485388 9.9L2.78091 11.6625C2.72944 12.2238 2.72944 12.7887 2.78091 13.35L0.485388 15.1125C-0.00827135 15.4875 -0.144028 16.1875 0.16451 16.7375L2.47237 20.775C2.78091 21.325 3.44735 21.55 4.01505 21.3L6.66848 20.1625C7.12511 20.4875 7.60643 20.775 8.11243 21.0125L8.47033 23.9C8.54438 24.525 9.07507 25 9.69214 25H14.2955C14.9126 25 15.4433 24.525 15.5173 23.9L15.8752 21.0125C16.3812 20.775 16.8625 20.4875 17.3192 20.1625L19.9726 21.3C20.5403 21.55 21.2067 21.325 21.5153 20.775L23.8231 16.7375C24.1317 16.1875 23.9959 15.5 23.5023 15.1125L21.2067 13.35C21.2438 13.0625 21.2561 12.7875 21.2561 12.5ZM12.0494 16.875C9.66746 16.875 7.72984 14.9125 7.72984 12.5C7.72984 10.0875 9.66746 8.125 12.0494 8.125C14.4313 8.125 16.3689 10.0875 16.3689 12.5C16.3689 14.9125 14.4313 16.875 12.0494 16.875Z" fill="#B1B1B1"/>
                                          </svg>
                                      </em>
                                      <span>
                                      Settings
                                      </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                          <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M25.7707 13.75L24.3147 15.2206L21.33 12.2059L22.7859 10.7353C22.9356 10.5871 23.1369 10.5041 23.3465 10.5041C23.5561 10.5041 23.7574 10.5871 23.907 10.7353L25.7707 12.6176C26.0764 12.9265 26.0764 13.4412 25.7707 13.75ZM11.6478 21.9706L20.4709 13.0588L23.4557 16.0735L14.6471 25H11.6478V21.9706ZM11.6478 14.7059C5.21238 14.7059 0 17.3382 0 20.5882V23.5294H8.73583V20.75L14.5597 14.8676C13.5988 14.75 12.6233 14.7059 11.6478 14.7059ZM11.6478 0C10.1032 0 8.62185 0.619746 7.52966 1.7229C6.43747 2.82606 5.82388 4.32226 5.82388 5.88235C5.82388 7.44245 6.43747 8.93865 7.52966 10.0418C8.62185 11.145 10.1032 11.7647 11.6478 11.7647C13.1924 11.7647 14.6737 11.145 15.7659 10.0418C16.8581 8.93865 17.4717 7.44245 17.4717 5.88235C17.4717 4.32226 16.8581 2.82606 15.7659 1.7229C14.6737 0.619746 13.1924 0 11.6478 0Z" fill="#B1B1B1"/>
                                          </svg>
                                      </em>
                                      <span>
                                      My Account
                                      </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript: void(0);" className="flex items-center space-x-3	">
                                      <em>
                                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M13 0C5.824 0 0 5.824 0 13C0 20.176 5.824 26 13 26C20.176 26 26 20.176 26 13C26 5.824 20.176 0 13 0ZM14.3 22.1H11.7V19.5H14.3V22.1ZM16.991 12.025L15.821 13.221C14.885 14.17 14.3 14.95 14.3 16.9H11.7V16.25C11.7 14.82 12.285 13.52 13.221 12.571L14.833 10.933C15.314 10.465 15.6 9.815 15.6 9.1C15.6 7.67 14.43 6.5 13 6.5C11.57 6.5 10.4 7.67 10.4 9.1H7.8C7.8 6.227 10.127 3.9 13 3.9C15.873 3.9 18.2 6.227 18.2 9.1C18.2 10.244 17.732 11.284 16.991 12.025Z" fill="#B1B1B1"/>
                                          </svg>
                                      </em>
                                      <span>
                                      Help & Support
                                      </span>
                                  </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </aside>
    )
}
// import React from "react";

// const Sidebar = () => {
//   return (
//     <div className="w-64 h-screen bg-gray-800 text-white p-6">
//       <div className="flex items-center mb-8">
//         <img
//           src="https://via.placeholder.com/40"
//           alt="Logo"
//           className="w-8 h-8"
//         />
//         <h2 className="text-2xl font-bold ml-2">Connect</h2>
//       </div>
//       <nav className="space-y-6">
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>🏠</span>
//           <span>Home</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>📝</span>
//           <span>Post in Community</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>✉️</span>
//           <span>Messages</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>📜</span>
//           <span>Explore Listings</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>📄</span>
//           <span>Holders</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>🕒</span>
//           <span>Schedules</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>💸</span>
//           <span>Bills & Payments</span>
//         </a>
//       </nav>
//       <div className="mt-8">
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>⚙️</span>
//           <span>Settings</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>👤</span>
//           <span>My Account</span>
//         </a>
//         <a href="#" className="flex items-center space-x-3 text-gray-400 hover:text-white">
//           <span>❓</span>
//           <span>Help & Support</span>
//         </a>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;
