import logo from './logo.svg';
import './App.css';
import ListingsPage from "./components/pages/listingpage/listingpage"
import CardDetailPage from './components/pages/detailpage/detailpage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
  
   
    <Router>
    <Routes>
      <Route path="/" element={<ListingsPage />} />
      <Route path="/detail" element={<CardDetailPage />} />
    </Routes>
  </Router>

  );
}

export default App;
