// ListingsPage.js
import React from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import Filter from "../../filter/filter";
import Card from "../../card/card";

const ListingsPage = () => {
  return (
    <div className="flex pl-72 pt-4 pr-4 pb-4 ">
      <Sidebar />
      <div className="flex-1 p">
        <Header />
        <div className="py-6 px-9 w-full float-left rounded-bl-3xl	rounded-br-3xl bg-red-50">
          <h2 className="text-2xl font-bold mb-4">Properties in Windsor, ON</h2>
          <Filter />
          <div className="grid grid-cols-3 gap-4 mt-4">
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            {/* Add more Card components as needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingsPage;
