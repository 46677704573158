import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCommentDots, faThList, faCalendarAlt, faFileInvoiceDollar, faCog, faUserCircle, faHeart, faMapMarkerAlt, faStar, faWifi, faSnowflake, faUtensils, faSmoking, faCar, faHomeLg, faBuilding } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../sidebar/sidebar';
import Header from '../../header/header';
import CardImage from "../../../assetes/image/cardimage.png";
import Rommate from "../../../assetes/image/1.png";
import RommateTwo from "../../../assetes/image/2.png";

const PropertyDetailPage = () => {
  const property = {
    title: "Apartment",
    location: "1995 Broadway, Campbell",
    price: "550",
    roommates: [
      { name: "Richard", avatar: Rommate },
      { name: "Gill", avatar: RommateTwo }
    ],
    description: "A cozy apartment with all the modern amenities you need.",
    amenities: ["wifi", "airConditioning", "kitchen", "smokers", "parking", "balcony"],
    reviews: { rating: 5.0, count: 14 },
    images: [
      CardImage,
      CardImage,
      CardImage
    ]
  };

  return (
    <div className="flex pl-72 pt-4 pr-4 pb-4 ">
      {/* Sidebar */}
      <Sidebar/>
      <div className="flex-1 p">
        <Header />
        <div className="py-6 px-9 w-full float-left rounded-bl-3xl	rounded-br-3xl bg-red-50">
          <h2 className="text-2xl font-bold mb-4">Properties in Windsor, ON</h2>
          {/* Image Gallery */}
          <div className="grid grid-cols-2 gap-4">
            {property.images.map((img, index) => (
              <img key={index} src={img} alt={`Room ${index + 1}`} className="rounded-lg object-cover w-full h-64" />
            ))}
          </div>

          {/* Details Section */}
          <div className="bg-white p-6 mt-6 shadow rounded-lg">
            {/* Location and Price */}
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-orange-500" />
                <span className="text-gray-700">{property.location}</span>
              </div>
              <span className="text-2xl font-semibold text-gray-800">${property.price} CAD / month</span>
            </div>

            {/* Roommates */}
            <div className="flex items-center mt-4">
              <span className="font-semibold text-gray-700">Roommates:</span>
              <div className="flex items-center ml-2 space-x-2">
                {property.roommates.map((roommate, index) => (
                  <div key={index} className="flex items-center space-x-1">
                    <img src={roommate.avatar} alt={roommate.name} className="w-8 h-8 rounded-full" />
                    <span>{roommate.name}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Description */}
            <p className="mt-4 text-gray-600">{property.description}</p>

            {/* Amenities */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-700">Amenities</h3>
              <div className="grid grid-cols-3 gap-4 mt-2">
                {property.amenities.includes("wifi") && (
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faWifi} className="text-orange-500" />
                    <span>WiFi</span>
                  </div>
                )}
                {property.amenities.includes("airConditioning") && (
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faSnowflake} className="text-orange-500" />
                    <span>Air Conditioning</span>
                  </div>
                )}
                {property.amenities.includes("kitchen") && (
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faUtensils} className="text-orange-500" />
                    <span>Kitchen</span>
                  </div>
                )}
                {property.amenities.includes("smokers") && (
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faSmoking} className="text-orange-500" />
                    <span>Smokers Allowed</span>
                  </div>
                )}
                {property.amenities.includes("parking") && (
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faCar} className="text-orange-500" />
                    <span>Parking</span>
                  </div>
                )}
                {property.amenities.includes("balcony") && (
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faHomeLg} className="text-orange-500" />
                    <span>Balcony</span>
                  </div>
                )}
              </div>
            </div>

            {/* Reviews */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-700">Reviews</h3>
              <div className="flex items-center space-x-2 mt-2">
                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                <span className="text-gray-700 font-semibold">{property.reviews.rating}</span>
                <span className="text-gray-500">({property.reviews.count} Reviews)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailPage;
